
  .help-hover:hover{
      color: white;
  }

  .c3-circle {
      stroke-width: 2px;
      stroke: #fff;
  }

  .c3-line-usd {
      stroke-width: 3px;
  }

  .c3-line-local {
      stroke-width: 3px;
  }

  .c3 .c3-axis-x path,
  .c3 .c3-axis-x line,
  .c3 .c3-axis-y path,
  .c3 .c3-axis-y line,
  .c3 .c3-axis-y2 path,
  .c3 .c3-axis-y2 line {
      stroke-width: 0px;
  }

  .c3-xgrid-focus { 
      display: none; 
  }

  .tesla-vectormap-hover-label {
      background-color: #cc0000;
      color: #eeeeee;
  }

  .jvectormap-zoomin { 
    position: absolute;
    top: 10px !important;
    left: unset;
    right: 10px !important;
  }
  .jvectormap-zoomout { 
    position: absolute;
    top: 30px !important;
    left: unset;
    right: 10px !important;
  }
